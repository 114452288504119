import React from "react";
import Conditional from "Commons/Conditional";
import useStyles from "./styles";

export type LayoutProps = {
    SideBarDrawer: JSX.Element
    SideBar: JSX.Element
    defaultActiveTab?: string
    drawerWidth: number
    initialised: boolean
    activeTab: string
}

const Layout: React.FC<LayoutProps> = (props) => {
    const {
        children,
        SideBar,
        SideBarDrawer,
        drawerWidth,
        initialised,
        activeTab
    } = props

    const drawerOpen = Boolean(activeTab);
    const classes = useStyles(drawerOpen && initialised ? drawerWidth : 0, initialised)()

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                {children}
            </div>
            <Conditional cond={initialised}>
                <div className={classes.sideBar}>
                    {SideBarDrawer}
                    {SideBar}
                </div>
            </Conditional>
        </div>
    )
}

export default Layout
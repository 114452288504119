import {createMuiTheme} from "@material-ui/core";

const appTheme = createMuiTheme({
    typography: {
        fontSize: 13,
        htmlFontSize: 13,
        fontFamily: ["XfinityBrown", "XfinityBrownBold"].join(","),
        body1: {
            fontSize: 13
        },
        h1: {
            fontSize: 24
        },
        h2: {
            fontSize: 20
        }
    },
    backgroundColors: {
        main : "#2D2D2D",
        dark : "#1B1B1B",
        light: "#FFFFFF",
        grey: "#ECECF3",
        trivia : {
            main : "#2B9CD8"
        }
    },
    colors : {
        info : "#898C90",
        desc : "#E4E4E3",
        success : "#00B471",
        danger : "#EF3B3B",
        checkmarkSuccess : "#00EA73",
    },
    palette: {
        primary: {
            main: '#2B9CD8'
        },
        secondary: {
            main: '#2D2D2D'
        },
        success: {
            main: '#00D267'
        },
        error: {
            main: '#E01E4A'
        }
    },
    sideBarWidth : 69,
    overrides: {
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#fff',
                borderColor: '#DDDDDD',
                borderRadius: 10,
                color: '#1F1F1F'
            },
            input: {
                '&::placeholder': {
                    color: '#9C9C9C',
                    opacity: 1,
                    fontWeight: 500
                },
                color: '#425FBB',
                fontWeight: 500,
                '&.Mui-disabled': {
                    color: '#9C9C9C'
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: 10,
                textTransform: 'none'
            },
            contained: {
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8
            },
            outlined: {
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
                borderWidth: '2px !important'
            },
            text: {
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8
            }
        },
        MuiSlider: {
            root: {
                color: '#1F69FF',
                height: 8,
            },
            thumb: {
                height: 24,
                width: 24,
                backgroundColor: '#1F69FF',
                border: '2px solid white',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                // display: 'none'
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 8,
                borderRadius: 4,
            },
            rail: {
                height: 8,
                borderRadius: 4,
                backgroundColor: 'gray'
            },
        }
    },
});

export default appTheme
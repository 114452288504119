import React, {useCallback, useEffect, useState} from "react";
import Conditional from "../Conditional";
import {useStep} from "../Hooks";
import {useQuiz} from "./QuizCtx";
import {Prompt, useHistory, useRouteMatch} from "react-router-dom";
import {QuizProps} from "./interfaces";
import LeaveQuizModal from "./LeaveQuizModal";
import {onUserJoinedGameCallback} from "./core/types";
import {addJoinedPlayer, removeJoinedPlayer} from "./store/actions";
import WaitNextQuestion from "./WaitNextQuestion";
import QuizEnded from "./QuizEnded";
import {RouteBuilder} from "Utils";

const Quiz: React.FC<QuizProps> = (props) => {

    const {quizSpecs, components, started, refreshFlag} = props
    const {quiz, dispatch, host, endQuizInstance, setEndQuizInstance} = useQuiz()
    const history = useHistory()
    const [showModal, setShowModal] = useState(false)
    const [blockNavigation, setBlockNavigation] = useState(true)
    const quizActions = quiz.getActions()
    const quizPolicies = quiz.getPolicies()
    const match = useRouteMatch<{ id: string }>(RouteBuilder.room.id.build())!

    const {Component, next, prev, goToLast, id} = useStep([
        ...(started ? [WaitNextQuestion] : []),
        ...components
    ])

    const handleUserJoin: onUserJoinedGameCallback = useCallback((data) => {
        if (data.isHost) {
            return
        }
        dispatch(addJoinedPlayer(data))
    }, [dispatch])

    const handleUserLeft: onUserJoinedGameCallback = useCallback((data) => {
        dispatch(removeJoinedPlayer(data))
    }, [dispatch])

    useEffect(() => {
        quiz.getActions().onUserJoinedGame(handleUserJoin)
        quiz.getActions().onUserLeftGame(handleUserLeft)
    }, [quiz, handleUserJoin, handleUserLeft])

    const handleGoBack = useCallback(() => {
        if (setEndQuizInstance) setEndQuizInstance()
        setShowModal(false)
    }, [setEndQuizInstance])

    const handleLeave = useCallback(async () => {
        await quizActions.leaveGame()
        if (host) {
            await quizActions.stop()            // sends api call to stop quiz instance
            quizActions.triggerHostLeaveGame()  // triggered only on host clicking confirm button in modal
        }
        quizPolicies.removeQuiz(quizActions.getInstanceId())

        setBlockNavigation(false)
        if (refreshFlag) history.go(0)
        else history.push(RouteBuilder.room.param(match?.params?.id).build())
    }, [history, quizActions, host, match, quizPolicies, refreshFlag])

    const leaveFromTheGame = useCallback(() => {
        setShowModal(true)
        quizSpecs.safeToLeave(quizActions.getInstanceId()).then(async safeToLeave => {
            // detects if quiz has ended, and triggers action to leave quiz instance if true
            if (!safeToLeave) {
                setShowModal(true)
                return
            }
            return handleLeave()
        })
        return false

    }, [handleLeave, quizActions, quizSpecs])

    const ended = quizSpecs.ended()

    return (
        <Conditional cond={!ended} onFalse={<QuizEnded/>}>
            <Prompt when={blockNavigation} message={leaveFromTheGame}/>
            <LeaveQuizModal onGoBack={handleGoBack} onLeave={handleLeave} show={(showModal || endQuizInstance ? true : false) && host}/>
            <Component next={next} prev={prev} goToLast={goToLast} initialId={id}/>
        </Conditional>
    )
}

export default Quiz
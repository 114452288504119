export const fullScreen = async () => {
    const videoElement: HTMLElement = document.getElementById("jitsi-video-container")!
    if (!document.mozFullScreen && !document.webkitFullScreen) {
        if (videoElement.mozRequestFullScreen) {
            await videoElement.mozRequestFullScreen()
        } else {
            await videoElement.webkitRequestFullScreen((Element as unknown as Element).ALLOW_KEYBOARD_INPUT)
        }
        await window.screen.orientation.lock("landscape-primary")
    }
}

export const exitFullscreen = async () => {
    if (document.mozCancelFullScreen) {
        await document.mozCancelFullScreen()
    } else if (document.webkitCancelFullScreen) {
        await document.webkitCancelFullScreen()
    } else {
        await document.exitFullscreen()
    }
    window.screen.orientation.unlock()
}
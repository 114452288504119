import {call, put, takeLatest} from "redux-saga/effects"
import {huddleRepo} from "Core/Huddle";
import * as types from "./types";
import {Action} from "../types";
import {setHuddle} from "./actions";


function* fetchHuddle({payload} : Action<string>) {
    const huddle = yield call(huddleRepo.get, payload);
    yield put(setHuddle(huddle));
}

function* huddleSaga() {
    yield takeLatest(types.FETCH_HUDDLE, fetchHuddle);
}

export default huddleSaga
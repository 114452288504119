import React, {useEffect, useState} from "react";
// import {Button} from "@material-ui/core";
import useStyles from "./styles";
import {device} from "../../Utils";
import cl from "classnames";
import {useRoom} from "Components/Room/RoomCtx";

const DeviceOrientation: React.FC = () => {

    const classes = useStyles()
    const {meeting} = useRoom()
    const [granted, setGranted] = useState(true)

    // const requestOrientationAccess = useCallback(() => {
    //     device.requestDeviceOrientationPermission().then(() => {
    //         device.onOrientation(meeting.getController().orientation)
    //         setGranted(true)
    //     }).catch(console.error)
    // }, [meeting])

    useEffect(() => {
        return meeting.getControllerQueue().onReady(() => {
            if (device.ios()) {
                Promise.resolve().then(async () => {
                    const granted = await device.orientationPermissionGranted()
                    if (!granted) {
                        setGranted(false)
                    }
                })
            } else {
                device.onOrientation(meeting.getController().orientation)
            }
        })
    }, [meeting])

    return (
        <div className={cl(classes.root, {[classes.showRoot]: !granted})}>
            {/* <Button
                onClick={requestOrientationAccess}
                className={classes.button}
            >
                Click here to allow this meeting to use your device's gyrosensors
            </Button> */}
        </div>
    )
}

export default DeviceOrientation
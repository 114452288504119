import TokenProvider from "./TokenProvider/TokenProvider";
import {ProviderName } from "./types";
import Jitsi from "./Jitsi";

const ProviderMap = {
    "Jitsi" : Jitsi,
    "Zoom" : Jitsi
}

class VideoProviderBuilder {

    private tokenProvider !: TokenProvider
    private container !: HTMLDivElement
    private provider : ProviderName = "Jitsi"
    private meetingId : string = ""

    constructor() {
        this.setProvider = this.setProvider.bind(this)
        this.setTokenProvider = this.setTokenProvider.bind(this)
        this.setContainer = this.setContainer.bind(this)
        this.setMeetingId = this.setMeetingId.bind(this)
        this.build = this.build.bind(this)
    }

    setProvider(provider : ProviderName){
        this.provider = provider
        return this
    }

    setTokenProvider(tokenProvider : TokenProvider){
        this.tokenProvider = tokenProvider
        return this
    }

    setContainer(container : HTMLDivElement){
        this.container = container
        return this
    }

    setMeetingId(meetingId : string){
        this.meetingId = meetingId
        return this
    }

    build() {
        return new ProviderMap[this.provider](this.container, this.tokenProvider, this.meetingId)
    }
}

export default VideoProviderBuilder
import React, {useCallback, useEffect, useRef} from "react";
import {Drawer} from "@material-ui/core";
import useStyles from "./styles";
import {useRoom} from "../Room/RoomCtx";
import {Conditional, DrawerHeader} from "Commons";
import {useRouteMatch, useLocation} from "react-router-dom";
import {matchPath} from "react-router";
import {RouteBuilder, screenUtils} from "Utils";
import {useVideo} from "./VideoCtx";
import {meetingStyleObject, withDynamicSideBarStyles} from "Components/Room/Layout/styles";
import {quizStyleObject} from "Commons/Quiz/Layout/styles";
import {VideoStatus} from "Core/Meeting/types";
import {tokenProvider} from "Core/Video/TokenProvider";

const Video: React.FC = () => {

    const classes = useStyles()

    const {video, initialised, host, meeting, meetingState: {sharing}} = useRoom()
    const {openVideo, open, closeVideo} = useVideo()
    const location = useLocation()
    const videoStatusRef = useRef<VideoStatus>("ended")
    const videoContainerRef = useRef<HTMLDivElement>(null)
    const match = useRouteMatch<{ id: string }>(RouteBuilder.room.id.build())!
    const meetingActions = meeting.getActions()
    const meetingStateController = meeting.getState()

    const isQuiz = !!matchPath(
        location.pathname,
        RouteBuilder.room.id.trivia.quizId.build()
    );
    const drawerClasses = withDynamicSideBarStyles(isQuiz ? quizStyleObject : meetingStyleObject)()

    useEffect(() => {
        closeVideo()
    }, [location, closeVideo])

    const removeVideo = useCallback(() => {
        video.getProvider().remove()
    }, [video])

    useEffect(() => {
        window.addEventListener("unload", removeVideo)
    }, [removeVideo])

    const handleVideoStart = useCallback(async () => {
        await video.getProvider().mute()
        videoStatusRef.current = "started"
    }, [video])

    const handleVideoPaused = useCallback(() => {
        videoStatusRef.current = "paused"
    }, [])

    const handleVideoEnded = useCallback(async () => {
        videoStatusRef.current = "ended"
    }, [])

    useEffect(() => {
        let offStarted = () => {
            },
            offPaused = () => {
            },
            offEnded = () => {
            },
            offReady = () => {
            },
            offMeetingReady = () => {
            },
            offModalInteraction = () => {
            },
            offScreenShare = () => {
            }

        if (initialised) {
            offReady = video.getQueue().onReady(async () => {
                const provider = video.getProvider()
                const muteVideo = meeting.getPolicies().shouldHideVideo(host)
                await provider.init({
                    screenShare: false,
                    muteVideo
                })

                // if(host){
                //     offMeetingReady = meeting.getQueue().onReady(async () => {
                //         setTimeout(() => {
                //             provider.onScreenSharingStatusChanged(({on}) => {
                //                 meetingActions.toggleScreenShare(on)
                //                 meetingStateController.set({sharing: on})
                //                 if (on) closeVideo()
                //             })
                //              provider.toggleScreenShare()
                //         }, 2000)
                //     })
                // }

                if (!host) {
                    offScreenShare = meetingActions.onToggleScreenShare(({sharing}) => {
                        meetingStateController.set({sharing})
                        if (!sharing) {
                            screenUtils.exitFullscreen()
                            closeVideo()
                        } else openVideo()
                    })
                }

                const controller = meeting.getController()
                offStarted = controller.onVideoControls("started", handleVideoStart)
                offPaused = controller.onVideoControls("paused", handleVideoPaused)
                offEnded = controller.onVideoControls("ended", handleVideoEnded)
                offModalInteraction = controller.onModalInteraction(closeVideo)
                provider.onAudioMuteStatusChanged(({muted}) => {
                    if (videoStatusRef.current === "started") {
                        provider.mute()
                    }
                })
                !muteVideo && openVideo()
            })
        }

        return () => {
            offStarted()
            offPaused()
            offEnded()
            offReady()
            offMeetingReady()
            offModalInteraction()
            offScreenShare()
            video.getQueue().setReady(false)
            videoStatusRef.current = "ended"
        }
    }, [video, meeting, initialised, openVideo, host, handleVideoStart, handleVideoPaused, handleVideoEnded, closeVideo, meetingActions, meetingStateController])

    useEffect(() => {
        const videoContainer = videoContainerRef.current
        if (initialised && videoContainer && match?.params?.id) {
            video
                .setProvider(
                    video
                        .getProviderBuilder()
                        .setProvider("Jitsi")
                        .setTokenProvider(tokenProvider)
                        .setContainer(videoContainer)
                        .setMeetingId(match.params.id)
                        .build()
                )
                .getQueue()
                .setReady()
        }
    }, [video, match?.params?.id, initialised])

    return (
        <Conditional cond={initialised}>
            <Drawer
                anchor={"right"}
                open={open}
                variant="persistent"
                classes={{paper: drawerClasses.paper}}
            >
                <DrawerHeader title={"Video"}/>
                <div id="jitsi-video-container" className={!host && sharing ? classes.screenShareVideoElement : classes.videoElement} ref={videoContainerRef}/>
            </Drawer>
        </Conditional>
    )
}

export default Video
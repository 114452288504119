import * as types from "./types"
import {Action} from "./actions";
import {Leaderboard} from "Core/Leaderboard/types";

const initialState: Leaderboard = {
    players : [],
    instance : {
        id: "",
        name: "",
        gameInstanceId: "",
        gameInstanceName: "",
        players : []
    }
};

const leaderboardReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case types.SET_MEETING_LEADERBOARD:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};

export default leaderboardReducer;

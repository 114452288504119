import {
    AnsweredQuestion,
    QuizInstance,
    InstanceState,
    OnAnswerData,
    PlayerFromJoinEvent,
    StoredQuiz,
    RewardsConfiguration,
    LeaderboardPlayer, PlayerPlace, Player
} from "./types";

class QuizPolicies {

    constructor() {
        this.joinPlayer = this.joinPlayer.bind(this)
        this.changeSelfStatusToInProgress = this.changeSelfStatusToInProgress.bind(this)
        this.setAnswers = this.setAnswers.bind(this)
        this.calculatePoints = this.calculatePoints.bind(this)
        this.getStoredQuiz = this.getStoredQuiz.bind(this)
        this.storeQuiz = this.storeQuiz.bind(this)
        this.removeQuiz = this.removeQuiz.bind(this)
        this.getPlayerReward = this.getPlayerReward.bind(this)
        this.getReward = this.getReward.bind(this)
        this.getPlayersCount = this.getPlayersCount.bind(this)
    }

    joinPlayer(playerFromEvent: PlayerFromJoinEvent, state: InstanceState) {
        const userId = playerFromEvent.userId
        let player = {[userId]: "inProgress" as InstanceState}


        if (state !== "Created" && playerFromEvent.userId === "Aborted") {
            player = {}
        }

        return player
    }

    changeSelfStatusToInProgress(players: QuizInstance["players"], userId: string): QuizInstance["players"] {
        if (players[userId]) {
            players[userId] = "InProgress"
        }
        return players
    }

    setAnswers(answer: OnAnswerData, answers?: AnsweredQuestion[]) {
        const {questionId, answerIndexes} = answer

        const userAnsweredQuestion = {
            questionId,
            answers: answerIndexes
        }

        let answeredQuestions: AnsweredQuestion[]

        if (!answers) {
            answeredQuestions = [userAnsweredQuestion]
        } else {
            const answered = answers.find(question => question.questionId === questionId)
            if (answered) {
                answered.answers = answerIndexes
                answeredQuestions = answers
            } else {
                answeredQuestions = [
                    ...answers,
                    userAnsweredQuestion
                ]
            }
        }

        return answeredQuestions
    }

    calculatePoints(totalPoints: number, totalTime: number, remainingTime: number) {
        const calculatedScore = Math.trunc((remainingTime / totalTime) * totalPoints)
        return calculatedScore > totalPoints ? totalPoints : calculatedScore;
    }

    getStoredQuiz(instanceId: string): StoredQuiz | null {
        try {
            const storedQuiz = JSON.parse(localStorage.getItem("quiz")!)
            return storedQuiz[instanceId] || null
        } catch {
            return null
        }
    }

    storeQuiz(questionId: string, instanceId: string) {
        localStorage.setItem("quiz", JSON.stringify({
            [instanceId]: {
                questionId,
                time: Date.now()
            }
        }))
    }

    removeQuiz(instanceId: string) {
        try {
            const storedQuiz = JSON.parse(localStorage.getItem("quiz")!)
            delete storedQuiz[instanceId]
            localStorage.setItem("quiz", JSON.stringify(storedQuiz))
        } catch {
            // TODO add some error logs
        }
    }

    getPlayerReward(rewardConfig: RewardsConfiguration, place: PlayerPlace) {
        return rewardConfig?.guestRewards[place]?.amount || 0
    }

    getReward(rewardConfig: RewardsConfiguration, players: LeaderboardPlayer[], userId: string, host: boolean) {
        if (host) {
            return rewardConfig?.hostReward?.amount || 0
        }

        const playerIndex = players.findIndex(player => player.id === userId)

        return rewardConfig?.guestRewards[playerIndex]?.amount || 0

    }

    getPlayersCount(players: Player) {
        return Object.keys(players).filter(id => {
            return players[id] !== "Aborted"
        }).length
    }

}

export default QuizPolicies
import mobile from "is-mobile";

export const isMobile = () => {
    return mobile({tablet: true, featureDetect: true});
}

type OnOrientationCB = (event: DeviceOrientationEvent) => void

export const onOrientation = (cb: OnOrientationCB) => {
    window.addEventListener("deviceorientation", cb, true);
    return () => window.removeEventListener("deviceorientation", cb, true);
}

export const orientationPermissionGranted = async () => {
    try {
        const state = await DeviceOrientationEvent.requestPermission()
        return state === "granted"
    } catch {
        return false
    }
}

export const ios = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const requestDeviceOrientationPermission = () => {
    console.log('requesting device orientation')
    return new Promise((resolve, reject) => {
        if (!DeviceOrientationEvent || !DeviceOrientationEvent.requestPermission) {
            console.log('device orientation denied')
            return reject()
        }

        DeviceOrientationEvent.requestPermission()
            .then(response => {
                console.log('device orientation granted')
                resolve(response)
            })
            .catch(reject)
    })
}
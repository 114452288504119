import React from "react";
import {Button, Grid} from "@material-ui/core";
import useStyles from "./styles";
import cl from "classnames";

type SubmitButtonProps = {
    onSubmit : (...args : any[]) => void,
    className ?: string
}

const SubmitButton : React.FC<SubmitButtonProps> = ({onSubmit, className, children}) => {

    const classes = useStyles()

    return (
        <Grid item>
            <Button
                onClick={onSubmit}
                variant="contained"
                className={cl(classes.submitButton, className)}
            >
                {children}
            </Button>
        </Grid>
    )
}

export default SubmitButton
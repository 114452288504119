import {call, put, takeLatest} from "redux-saga/effects"
import {meetingRepository} from "../../Core/Meeting";
import { setMeeting} from "./actions";
import * as types from "./types";
import {Action} from "../types";
import {HotspotAnalyticsData} from "../../Core/Meeting/types";


function* fetchMeeting({payload} : Action<string>) {
    const meeting = yield call(meetingRepository.get, payload);
    yield put(setMeeting(meeting));
}

 function* sendHotspotAnalytics({payload} : Action<HotspotAnalyticsData>) {
    yield call(meetingRepository.postHotspotAnalytics, payload) 
}

function* meetingSaga() {
    yield takeLatest(types.FETCH_MEETING, fetchMeeting);
    yield takeLatest(types.POST_HOTSPOT_ANALYTICS, sendHotspotAnalytics);
}


export default meetingSaga
export const Routes = {
    ROOM: "/room",
    TRIVIO: "/trivio",
    MEETING: "/room",
    ENTER_EVENT: "/enter-event",
    TRIVIA: "/trivia",
    ID: "/:id",
    APP: "/:app",
    QUIZ_ID: "/:quizId",
    AUTH: "/auth",
    OPTIONAL: "?"
}

class RouteBuilder {
    private route: string = ""

    get room() {
        this.route += Routes.ROOM
        return this
    }

    get trivio() {
        this.route += Routes.TRIVIO
        return this
    }

    get meeting() {
        this.route += Routes.MEETING
        return this
    }

    get enterEvent() {
        this.route += Routes.ENTER_EVENT
        return this
    }

    get trivia() {
        this.route += Routes.TRIVIA
        return this
    }

    get id() {
        this.route += Routes.ID
        return this
    }

    get quizId() {
        this.route += Routes.QUIZ_ID
        return this
    }

    get auth() {
        this.route += Routes.AUTH
        return this
    }

    get app() {
        this.route += Routes.APP
        return this
    }

    optional() {
        this.route += Routes.OPTIONAL
        return this
    }

    param(param: string) {
        this.route += `/${param}`
        return this
    }

    build() {
        const route = this.route
        this.route = ""
        return route
    }
}

export default new RouteBuilder()
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    submitButton: {
        background: "#2A9CD8",
        color: "white",
        borderRadius: 40,
        padding: theme.spacing(2, 8),
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
        "&:hover": {
            color: "black"
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 5),
            marginTop: theme.spacing(3),
        },
    },
}))

export default useStyles
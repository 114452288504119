import React from "react";
import {Grid, IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import useStyles from "./styles";
import Text from "Commons/Text"
import Conditional from "Commons/Conditional";

export type DrawerHeaderProps = {
    title : string,
    onClose ?: () => void
}

const DrawerHeader : React.FC<DrawerHeaderProps> = ({title, onClose }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.drawerHeader} justify={"space-between"} alignItems="center">
            <Grid item>
                <Text color="dark">
                    {title}
                </Text>
            </Grid>
            <Conditional cond={onClose}>
                <Grid item>
                    <IconButton onClick={onClose} className={classes.closeIcon}>
                        <CloseIcon fontSize={"small"}/>
                    </IconButton>
                </Grid>
            </Conditional>
        </Grid>
    )
}

export default DrawerHeader
import {createContext, useContext, FC} from "react";
import Quiz from "./core/Quiz";
import {QuizInstance} from "./core/types";
import {Action} from "./store/actions";
import QuizSpecs from "./core/QuizSpecs";
import {AnswersProps} from "./interfaces";

type QuizData = {
    quiz : Quiz
    onUserJoined : () => void
    state : QuizInstance
    dispatch : (data : Action) => void
    allParticipants : number | undefined,
    showEndGameButton ?: boolean,
    tokenIcon : string
    pointClassname ?: string
    quizSpecs : QuizSpecs
    Answers : FC<AnswersProps>
    host : boolean,
    backgroundImage ?: string,
    rainbowBackground ?: boolean,
    triviaLogo : string,
    endScreenLogo ?: string,
    countdownText ?: string,
    boldHeader ?: boolean,
    endQuizInstance ?: boolean,
    activeTab?: string
    setEndQuizInstance ?: () => void, 
    onQuizFinished ?: () => void
}

export const QuizCtx = createContext({})

export const useQuiz = () => {
    return useContext(QuizCtx) as QuizData
}
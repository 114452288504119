import React, {lazy, Suspense} from "react"
import {Route, Switch} from "react-router-dom";
import {RouteBuilder} from "./Utils";

const Room = lazy(() => import("./Components/Room"));
const MeetingQuiz = lazy(() => import("./Components/MeetingQuiz"));
const Huddle = lazy(() => import("./Components/Huddle"));
const AuthPage = lazy(() => import("./Commons/AuthProvider/AuthPage"));
const AuthRedirectPage = lazy(() => import("./Commons/AuthProvider/AuthRedirectPage"));

const AppRouter: React.FC = () => {
    return (
        <Suspense fallback={null}>
            <Switch>
                <Route path={RouteBuilder.room.id.build()} component={Room} exact/>
                <Route path={RouteBuilder.room.id.trivia.quizId.build()} component={MeetingQuiz} exact/>
                <Route path={RouteBuilder.trivio.id.build()} component={Huddle} exact/>
                <Route path={RouteBuilder.auth.app.id.build()} component={AuthPage} />
                <Route path={RouteBuilder.auth.app.build()} component={AuthRedirectPage} />
            </Switch>
        </Suspense>
    )
}

export default AppRouter
import {makeStyles, Theme} from "@material-ui/core";

const useStyles = (drawerWidth : number, initialised : boolean) => makeStyles((theme: Theme) => ({
    root : {
        display: "grid",
        gridTemplateColumns: `${drawerWidth ? `calc(100% - ${drawerWidth + theme.sideBarWidth}px)` : `1fr`} ${initialised ? theme.sideBarWidth : 0}px`,
        transition: theme.transitions.create(["all"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('xs')]: {
            display: "flex",
        },
    },
    main : {
        position : "relative",
        width : "100%",
        height : "100%",
        overflow: "hidden",
        backgroundColor: "#E8E8EE",
        zIndex: 0
    },
    sideBar : {
        width : 96,
        position : "fixed",
        right : 0,
        [theme.breakpoints.down('md')]: {
            width : 69,
        },
    }
}))

export default useStyles
import React from "react";
import { Grid, GridProps } from "@material-ui/core";
import useStyles from "./styles";
import cl from "classnames";
import { useQuiz } from "../QuizCtx";

const QuizContainer: React.FC<GridProps> = ({ children, className, ...rest }) => {

    const classes = useStyles()
    const { backgroundImage, rainbowBackground } = useQuiz()

    return (
        <Grid
            container
            className={cl(classes.root, className)}
            style={{ background: backgroundImage ? `url(${backgroundImage})` : '', animation: rainbowBackground ? '20000ms ease-in-out infinite color-changer' : '' }}
            {...rest}
        >
            {children}
        </Grid>
    )
}

export default QuizContainer
import {QuizInstance} from "../core/types";
import {Question} from "../interfaces";

export const getInstance = (state: QuizInstance) => {
    return state
}

export const getGameDefinition = (state: QuizInstance) => {
    return state.gameDefinition
}

export const getInstanceLeaderboard = (state: QuizInstance) => {
    return state.gameDefinition
}

export const getQuizPlayersIds = (state: QuizInstance) => {
    const players = state.players

    const quizPlayersIds: string[] = []

    Object.keys(players).forEach(id => {
        if (players[id] !== "Aborted") {
            quizPlayersIds.push(id)
        }
    })
    return quizPlayersIds
}

export const getInstancePlayersCount = (state: QuizInstance) => {
    const players = state.players
    const joinedPlayersCount = Object.keys(players).filter(id => {
        return players[id] !== "Aborted"
    }).length

    return joinedPlayersCount
}

export const getUserAnsweredCount = (questionId: string) => (state: QuizInstance) => {
    const users = state.answeredQuestions
    const serAnsweredCount = Object.keys(users).reduce((count, userID) => {
        const answered = users[userID].find(q => q.questionId === questionId)
        if (answered) {
            ++count
        }
        return count
    }, 0)

    return serAnsweredCount
}

export const getInstancePlayersAnswers = (state: QuizInstance) => {
    return state.answeredQuestions
}

export const getAllQuestions = (state: QuizInstance) => {
    return state.gameDefinition.questions
}

export const getUserCorrectAnsweredQuestions = (userId: string) => (state: QuizInstance) => {
    const userAnsweredQuestions = state.answeredQuestions[userId] || []
    const allQuestions = state.gameDefinition.questions

    return userAnsweredQuestions.reduce((correctAnswered, questions) => {
        const question = allQuestions.find(q => q.id === questions.questionId)!
        if (question.correctAnswerChoices.includes(questions.answers[0])) {
            ++correctAnswered[0]
            correctAnswered[1] = correctAnswered[1] + question.points
        }
        return correctAnswered
    }, [0, 0])
}

export const getInstanceQuestions = (state: QuizInstance): Question[] => {

    const answeredQuestions = (questionId: string, index: number) => {
        return Object.keys(state.answeredQuestions).reduce((answeredCount, userId) => {
            const answered = state.answeredQuestions[userId].find(q => q.questionId === questionId)
            if (answered && answered.answers.includes(index)) {
                ++answeredCount
            }
            return answeredCount
        }, 0)
    }

    return state.gameDefinition.questions.map(question => {
        const id = question.id
        return {
            id,
            title: question.questionText,
            description: question.explanation,
            points: question.points,
            image: question.images && question.images.length > 0 ? question.images[0] : '',
            timeLimit: question.maxTime ? question.maxTime.split(':').reduce((acc,time) => (60 * acc) + +time, 0) : 20,
            answers: question.answerChoices.map((answer, index) => {
                return {
                    id: answer,
                    answer: answer,
                    answered: answeredQuestions(id, index),
                    correct: question.correctAnswerChoices.includes(index),
                }
            })
        }
    })
}

export const getInstanceLeaderboardPlayers = (state: QuizInstance) => {
    return state.leaderboard.players.sort((a, b) => a.position > b.position ? 1 : -1)
}
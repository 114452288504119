import {HttpClient} from "Commons/AuthProvider/types";
import { QuizInstance} from "./types";
import QuizInstanceRepository from "./QuizInstanceRepository";

const baseRoute = "/api/game/definition"

class QuizDefinitionRepository {
    constructor(private httpClient: HttpClient, private instanceRepo: QuizInstanceRepository) {
        this.createInstance = this.createInstance.bind(this)
    }

    getInstance(instanceId : string){
        return this.instanceRepo.get(instanceId)
    }

    async createInstance(definitionId: string, meetingId : string): Promise<QuizInstance> {
        return this.httpClient.post(`${baseRoute}/${definitionId}/instance`, {
            meetingId : meetingId
        })
    }

    getPlayerPoints(playerId : string, gameInstanceId : string){
        return this.instanceRepo.getPlayerPoints(playerId, gameInstanceId)
    }
}

export default QuizDefinitionRepository
import {Credentials, HttpClient, SSOUser, User, UserToken, VisitorCredentials} from "./types";

const baseRoute = "/api/user"

class UserService {
    constructor(private httpClient: HttpClient) {
        this.ssoLogin = this.ssoLogin.bind(this)
        this.authenticate = this.authenticate.bind(this)
        this.authenticateVisitor = this.authenticateVisitor.bind(this)
        this.store = this.store.bind(this)
        this.remove = this.remove.bind(this)
        this.getUser = this.getUser.bind(this)
    }

    ssoLogin(ssoUser: SSOUser): Promise<UserToken> {
        return this.httpClient.post(`${baseRoute}/sso`, ssoUser)
    }

    authenticate(credentials: Credentials): Promise<UserToken> {
        return this.httpClient.post(`${baseRoute}/authenticate`, {...credentials})
    }

    async authenticateVisitor(credentials: VisitorCredentials): Promise<UserToken> {
        const cred = {
            ...credentials,
            firstName: credentials.fullName.split(" ")[0]
        }
        return await this.httpClient.post(`${baseRoute}/visitor`, {...cred})
    }

    store(user: User, ssoId ?: string) {
        if(ssoId){
            user.ssoId = ssoId
        }
        localStorage.setItem("user", JSON.stringify(user))
    }

    remove() {
        localStorage.removeItem("user")
    }

    getUser(): User {
        const user = localStorage.getItem("user") || "{}"
        return JSON.parse(user)
    }

    // when called, will return the value stored, which should be
    // a meeting code.
    getSurveySent() {
        return localStorage.getItem("surveySent") || ""
    }

    // when called, will store the value passed as a param,
    // which should be meeting code
    setSurveySent(meetingCode: string) {
        localStorage.setItem("surveySent", meetingCode)
    }
}

export default UserService
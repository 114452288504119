import {BackendLeaderboardPlayer, LeaderboardPlayer,} from "./types";

export const toFrontend = (players: BackendLeaderboardPlayer[]) : LeaderboardPlayer[] => {
    return players.map(player => ({
        id: player.userId,
        position: player.currentPosition,
        firstName: player.firstName || '',
        lastName: player.lastName || '',
        points: player.totalPoints,
        email: player.userEmail || '',
        ssoId: player.ssoId || '',
        name: player.userName,
    }))
}
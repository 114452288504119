import {makeStyles, withStyles} from "@material-ui/core";
import briskImage from "../assets/bricks.jpg"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(6, 5),
        backgroundImage: `url(${briskImage})`,
        height: "100%",
        minHeight: "100vh",
        color: "white",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 1),
        },
    },
    triviaLogo: {
        width: 800,
        height: 520,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: 'auto'
        },
    },
    waitingCircle : {
        display : "block",
        margin : "auto",
        marginTop : 200,
        color : "grey",
    }
}))

export const withCircleStyles = withStyles({
    root: {
        display: "block",
        margin: "auto",
        marginTop: 200,
        color: "grey",
    }
})

export default useStyles
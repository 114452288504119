import {
    Callback,
    NavigationScene,
    OnCloseModalData,
    OnExperienceLoadedData, OnModalInteractionData,
    OnHotspotInteractionData,
    OnVideoControlData,
    onMobileModalInteraction,
    VideoStatus
} from "./types";
import Queue from "../Queue";

class MeetingController {

    private queue = new Queue()

    constructor(private meetingWindow: Window, private targetOrigin: string, private host: boolean) {
        this.onLoaded = this.onLoaded.bind(this)
        this.loaded = this.loaded.bind(this)
        this.navigate = this.navigate.bind(this)
        this.hostControls = this.hostControls.bind(this)
        this.guidedTour = this.guidedTour.bind(this)
        this.onHostCloseModal = this.onHostCloseModal.bind(this)
        this.onVideoControls = this.onVideoControls.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.lockControls = this.lockControls.bind(this)
        this.environment = this.environment.bind(this)
        this.orientation = this.orientation.bind(this)
    }

    setHost(host: boolean) {
        this.host = host
    }

    getQueue() {
        return this.queue
    }

    onLoaded(cb: Callback) {

        const listener = (event: { data: OnExperienceLoadedData }) => {
            if (event.data.action === "loaded") {
                cb()
            }
        }

        window.addEventListener("message", listener)

        return () => window.removeEventListener("message", listener)
    }

    loaded(isHost: boolean) {
        this.meetingWindow.postMessage({
            action: "loaded",
            data: {
                isHost
            }
        }, this.targetOrigin)
    }

    navigate(scene: NavigationScene) {
        this.meetingWindow.postMessage({
            action: "navigate",
            isHost: this.host,
            data: {
                isHost: this.host,
                scene
            }
        }, this.targetOrigin)
    }

    hostControls(key: string) {
        this.meetingWindow.postMessage({
            action: "hostControlled",
            isHost: this.host,
            data: {
                isHost: this.host,
                key
            }
        }, this.targetOrigin)
    }

    guidedTour(guided: boolean) {
        this.meetingWindow.postMessage({
            action: "guidedTour",
            isHost: this.host,
            data: {
                isHost: this.host,
                guided
            }
        }, this.targetOrigin)
    }

    onHostCloseModal(cb: Callback) {

        const listener = (event: { data: OnCloseModalData }) => {
            if (event.data.action === "modal-interaction" && event.data.data.event === "closed") {
                cb()
            }
        }

        window.addEventListener("message", listener)

        return () => window.removeEventListener("message", listener)
    }

    onVideoControls(status: VideoStatus, cb: Callback) {

        const listener = (event: { data: OnVideoControlData }) => {
            if (event.data.action === "video-controls" && event.data.data.status === status) {
                cb()
            }
        }

        window.addEventListener("message", listener)

        return () => window.removeEventListener("message", listener)
    }

    closeModal() {
        this.meetingWindow.postMessage({
            action: "hostControlled",
            operations: "close-modal",
            data: {
                isHost: this.host,
                key: "asdasd"
            },
            isHost: this.host,
        }, this.targetOrigin)
    }

    lockControls(area: string, locked: boolean) {
        this.meetingWindow.postMessage({
            action: "hostControlled",
            operations: "lock",
            isHost: this.host,
            data: {
                isHost: this.host,
                area,
                locked
            }
        }, this.targetOrigin)
    }

    environment(mobile: boolean) {
        this.meetingWindow.postMessage({
            action: "environment",
            isHost: this.host,
            data: {
                isHost: this.host,
                device: mobile ? "mobile" : "desktop"
            }
        }, this.targetOrigin)
        return this
    }

    onModalInteraction(cb: Callback) {
        const listener = (event: { data: OnModalInteractionData }) => {
            if (event.data.action === "modal-interaction" && event.data.data.event === "open") {
                cb()
            }
        }

        window.addEventListener("message", listener)

        return () => window.removeEventListener("message", listener)
    }

    orientation(event : DeviceOrientationEvent){
        this.meetingWindow.postMessage({
            action: "orientation",
            isHost: this.host,
            data: {
                absolute : event.absolute,
                alpha: event.alpha,
                beta : event.beta,
                gamma : event.gamma
            }
        }, this.targetOrigin)
    }

    onHotspotInteraction(cb: (hotspotInteractionData: Object) => void) {
        const listener = (event: { data: OnHotspotInteractionData }) => {
            if (event.data.action === "hotspot-interaction") {
                cb(event.data.data)
            }
        }
        window.addEventListener("message", listener)
        return () => window.removeEventListener("message", listener)
    }

    onUserMobileModalInteraction(cb: (mobileModalInteractionData: Object) => void) {
        const listener = (event: { data: onMobileModalInteraction }) => {
            
            if (event.data.action === "mobile-modal-interaction") {
                cb(event.data.data.event)
            }
        }

        window.addEventListener("message", listener)

        return () => window.removeEventListener("message", listener)
    }
}

export default MeetingController
export const jitsiDomain = "jitsi.xureal.com"
export const domain = '8x8.vc'

export const options = {
    configOverwrite: {
        prejoinPageEnabled: false,
        subject: "Room",
        notifications : [],
        startWithAudioMuted : true,
        startWithVideoMuted : false,
        disableAudioLevels : true,
    },
    interfaceConfigOverwrite: {
        TILE_VIEW_MAX_COLUMNS: 1,
        VIDEO_LAYOUT_FIT: 'height',
        DISABLE_PRESENCE_STATUS: true,
        MAXIMUM_ZOOMING_COEFFICIENT: 1,
        CONNECTION_INDICATOR_DISABLED: true,
        TOOLBAR_BUTTONS: [
            'microphone', 'mute-everyone', "tileview", "fullscreen"
        ],
        TOOLBAR_TIMEOUT: 1000,
        SHARING_FEATURES : []
    },
    roomName: 'vpaas-magic-cookie-fd795bae43f847fe87674bcd773ffb6d/MyRoom',
}
import {RoomProvider} from 'Components/Room/RoomCtx';
import React, {useCallback, useLayoutEffect, useState} from 'react';
import 'animate.css';
import AppRouter from "./AppRouter";
import {hooks} from "Commons";
import {meeting} from "Core/Meeting";
import {video} from "Core/Video";
import {playerBalance} from "./Core/PlayerBalance";
import {MEETING_CONTENT_URL} from "./Config/constants";
import {VideoProvider} from "./Components/Video/VideoCtx";
import Video from "./Components/Video";
import DeviceOrientation from 'Commons/DeviceOrientation';

function App() {
    hooks.useOnInitialLoad()

    const [initialised, setInitialised] = useState(false)
    const [host, setHost] = useState(false)
    const [open, setOpen] = useState(true)
    const [lockPop, setLockPop] = useState({show: false, locked: true})
    const [meetingState, setMeetingState] = useState(meeting.getState().initial())
    const [mute, setMute] = useState(false)

    const openVideo = useCallback(() => {
        setOpen(true)
    }, [])

    const closeVideo = useCallback(() => {
        setOpen(false)
    }, [])

    useLayoutEffect(() => {
        meeting.getState().setStateSetter(setMeetingState)
        meeting
            .getControllerBuilder()
            .setOriginTarget(MEETING_CONTENT_URL as string)
    }, [])

    return (
        <RoomProvider
            meeting={meeting}
            video={video}
            playerBalance={playerBalance}
            lockPop={lockPop}
            setLockPop={setLockPop}
            host={host}
            initialised={initialised}
            setInitialised={setInitialised}
            meetingState={meetingState}
            setMeetingState={setMeetingState}
            setHost={setHost}
            mute={mute}
            setMute={setMute}
        >
            <VideoProvider open={open} closeVideo={closeVideo} openVideo={openVideo}>
                    <Video/>
                    <AppRouter/>
                    <DeviceOrientation />
            </VideoProvider>
        </RoomProvider>
    );
}

export default App;

import {Leaderboard} from "Core/Leaderboard/types";
import * as types from "./types";

export const setMeetingLeaderboard = (leaderboard: Leaderboard) => ({
    type: types.SET_MEETING_LEADERBOARD,
    payload: leaderboard
} as const)

export const fetchMeetingLeaderboard = (meetingId : string) => ({
    type: types.FETCH_MEETING_LEADERBOARD,
    payload : meetingId
} as const)

export type Action =
    | ReturnType<typeof setMeetingLeaderboard>
    | ReturnType<typeof fetchMeetingLeaderboard>

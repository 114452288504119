import {Huddle, HuddleActivePlayers} from "Core/Huddle/types";
import * as types from "./types";

export const setHuddle = (huddle: Huddle) => ({
    type: types.SET_HUDDLE,
    payload: huddle
} as const)

export const setHuddleActivePlayers = (activePlayers: HuddleActivePlayers) => ({
    type: types.SET_HUDDLE_ACTIVE_PLAYERS,
    payload: activePlayers
} as const)

export const fetchHuddle = (huddleId: Huddle["id"]) => ({
    type: types.FETCH_HUDDLE,
    payload: huddleId
} as const)

export type Action = ReturnType<typeof setHuddle>
    | ReturnType<typeof setHuddleActivePlayers>
    | ReturnType<typeof fetchHuddle>
import {all} from "redux-saga/effects"
import meetingSaga from "./meeting/saga";
import leaderboardSaga from "./leaderboard/saga";
import playerBalanceSaga from "./playerBalance/saga";
import huddleSaga from "./huddle/saga";

export default function* rootSaga() {
    yield all([
        meetingSaga(),
        huddleSaga(),
        leaderboardSaga(),
        playerBalanceSaga(),
    ])
}
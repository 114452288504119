import {HttpClient} from "Commons/AuthProvider/types";
import {Huddle} from "./types";

const baseRoute = ""

class HuddleRepository {
    constructor(private httpClient: HttpClient) {
        this.get = this.get.bind(this)
    }

    get(huddleId : string): Promise<Huddle> {
        return this.httpClient.get(`${baseRoute}/${huddleId}`)
    }
}

export default HuddleRepository
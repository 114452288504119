import * as types from "./types";
import {PlayerBalance} from "Core/PlayerBalance/types";

export const setPlayerBalance = (playerBalance: PlayerBalance) => ({
    type: types.SET_PLAYER_BALANCE,
    payload: playerBalance
} as const)

export const addPlayerPoints = (playerBalance : PlayerBalance & {meetingId : string}) => ({
    type: types.ADD_PLAYER_POINTS,
    payload: playerBalance
} as const)

export const fetchPlayerBalance = (userId : string, meetingId : string) => ({
    type: types.FETCH_PLAYER_BALANCE,
    payload : {userId, meetingId}
} as const)

export type Action = ReturnType<typeof setPlayerBalance>
    | ReturnType<typeof addPlayerPoints>
    | ReturnType<typeof fetchPlayerBalance>

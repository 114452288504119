import {call, put, takeLatest} from "redux-saga/effects"
import {setPlayerBalance} from "./actions";
import * as types from "./types";
import {Action} from "../types";
import {playerBalanceRepository} from "Core/PlayerBalance";
import {PlayerBalanceRequestData} from "Core/PlayerBalance/types";
import {fetchMeetingLeaderboard} from "../leaderboard/actions";

function* fetchPlayerBalance({payload} : Action<PlayerBalanceRequestData>) {
    const playerBalance = yield call(playerBalanceRepository.get, payload.userId, payload.meetingId);
    yield put(setPlayerBalance(playerBalance));
}

function* addPlayerPoints({payload} : Action<PlayerBalanceRequestData>) {
    yield call(playerBalanceRepository.add, payload);
    const playerBalance = yield call(playerBalanceRepository.get, payload.userId, payload.meetingId);
    yield put(setPlayerBalance(playerBalance));
    yield put(fetchMeetingLeaderboard(payload.meetingId));
}

function* playerBalanceSaga() {
    yield takeLatest(types.FETCH_PLAYER_BALANCE, fetchPlayerBalance);
    yield takeLatest(types.ADD_PLAYER_POINTS, addPlayerPoints);
}

export default playerBalanceSaga
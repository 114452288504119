import React, {useEffect, useState} from "react";
import { Grid, Hidden } from "@material-ui/core";
import TimerIcon from '@material-ui/icons/Timer';
import useStyles from "./styles";
import cl from "classnames";
import Title from "Commons/Title";
import useSelector from "../store/useSelector";
import { getInstancePlayersCount } from "../store/selectors";

type HeaderProps = {
    timer?: boolean
    title?: string
    completed?: boolean
    duration: number
    play?: boolean
    onTimerComplete?: () => void
    showTrivioLogo?: boolean
}

const Header: React.FC<HeaderProps> = (props) => {
    const {
        timer,
        title = "Quiz name",
        onTimerComplete,
        completed,
        duration,
    } = props
    const classes = useStyles()
    const allPlayers = useSelector(getInstancePlayersCount)
    const [internalTimer, setTimer] = useState(duration || 20)
    
    useEffect(() => {
       setTimer(duration)
    }, [title, duration, setTimer]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (internalTimer >= 0) setTimer(internalTimer - 1) 
            if (internalTimer === 0 && onTimerComplete) {
                onTimerComplete()
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [internalTimer, onTimerComplete]);

    return (
        <Grid
            container
            item
            justify="space-evenly"
            alignItems="center"
            className={classes.root}
        >
            <Grid xs={4} item container alignItems="center" justify="flex-start">
                <Title sub className={classes.headerTitle}>
                    {title}
                </Title>
            </Grid>
            <Grid xs={4} item>
                {timer && (
                    <Grid container spacing={0} alignItems="center" justify="center" className={classes.timeContainer}>
                        <Grid xs={6} item container alignItems="center" justify="flex-end" direction="row" className={classes.timeIconContainer}>
                            <TimerIcon className={cl(classes.timerIcon, { [classes.completed]: completed })} />
                        </Grid>
                        <Grid xs={6} item container direction="row" alignItems="center" justify="flex-start" className={classes.countdownTimer}>
                        <div className={cl(classes.remainingTime, { [classes.completed]: true })}>
                            {internalTimer}
                        </div>
    
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid xs={4} item container alignItems="center" justify="flex-end" >
                <Hidden xsDown>
                    <Title sub size={16}>
                        <Grid container alignItems={"center"} spacing={2} className={classes.activPayerItems}>
                            <Grid item>
                                <div className={classes.activeDot} />
                            </Grid>
                            <Grid item className={classes.activePlayerTxt}>
                                {allPlayers} {"players"}
                            </Grid>
                        </Grid>
                    </Title>
                </Hidden>
            </Grid>
        </Grid>
    )
}

export default Header
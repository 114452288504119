import HubConnectionFactory from "Core/HubConnection"
import {default as Meeting} from "./Meeting"
import {default as MeetingRepository} from "./MeetingRepository"
import {httpClient} from "Config";
import ControllerBuilder from "./ControllerBuilder";
import Actions from "./Actions";
import MeetingState from "./MeetingState";
import MeetingPolicies from "./MeetingPolicies";

export {default as Navigator} from "./MeetingController"

export const meeting = new Meeting(
    new ControllerBuilder(),
    new Actions(new HubConnectionFactory().setHub("meetings").build()),
    new MeetingState(),
    new MeetingRepository(httpClient),
    new MeetingPolicies()
)
export const meetingRepository = new MeetingRepository(httpClient)

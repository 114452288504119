import React, {createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {
    AuthContext,
    ConfigureHttpClientOptions,
    Credentials,
    HttpClient,
    SSOUser,
    VisitorCredentials
} from "./types";
import useConfigurationHttpClient from "./useConfigurationHttpClient";
import TokenService from "./TokenService";
import UserService from "./UserService";
import {useHistory} from "react-router-dom";

const AuthCtx = createContext({})

type AuthProviderProps = {
    children: any,
    httpClient: HttpClient,
    tokenRefreshIntervalInMinutes: number
} & Partial<ConfigureHttpClientOptions>

const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const {children, httpClient, loginUrl, tokenRefreshIntervalInMinutes} = props
    const history = useHistory()

    const userService = useMemo(() => new UserService(httpClient), [httpClient])
    const tokenService = useMemo(() => new TokenService(httpClient), [httpClient])
    const [authenticated, setAuthenticated] = useState(!tokenService.isExpired())

    useConfigurationHttpClient(httpClient, {
        loginUrl: loginUrl,
        token: tokenService.get()
    })

    const refreshToken = useCallback(() => {
        tokenService.refresh()
            .then(token => tokenService.store(token))
            .catch((err) => {
                console.log("Failed to refresh token: ", err)
            })
    }, [tokenService])

    useEffect(() => {
        if (!tokenService.isExpired()) {
            refreshToken()
        }

        setTimeout(() => {
            refreshToken()
        }, tokenRefreshIntervalInMinutes * 1000 * 60)

    }, [tokenRefreshIntervalInMinutes, refreshToken, tokenService])

    useLayoutEffect(() => {
        if (!authenticated) {
            tokenService.remove()
            userService.remove()
            if (loginUrl) {
                history.push(loginUrl)
            }
        }
    }, [authenticated, history, loginUrl, tokenService, userService])

    const getUser = useCallback(() => {
        return userService.getUser()
    }, [userService])

    const ssoLogin = useCallback(async (data: SSOUser) => {
        const {user, accessToken} = await userService.ssoLogin(data)
        tokenService.store(accessToken)
        userService.store(user, data.ssoId)
        setAuthenticated(true)
    }, [tokenService, userService])

    const visitorLogin = useCallback(async (credentials: Credentials & VisitorCredentials) => {
        const {user, accessToken} = await userService.authenticateVisitor(credentials)
        tokenService.store(accessToken)
        userService.store(user)
        setAuthenticated(true)
    }, [tokenService, userService])

    const logout = useCallback(() => {
        tokenService.remove()
        userService.remove()
        setAuthenticated(false)
    }, [tokenService, userService])
    
    // const sendSurveyEmail = useCallback((meetingCode: string) => {
    //   const { email, firstName, roles } = userService.getUser();
    //   const storedSurveyMeeting = userService.getSurveySent();
    //   if (roles !== "Visitor" && storedSurveyMeeting !== meetingCode) {
    //     // console.log("SURVEY USER", email, firstName, meetingCode);

    //     const templateData = {
    //       to_email: email,
    //       to_name: firstName,
    //     };

    //     return emailjs
    //       .send(
    //         "service_31c15fe",
    //         "template_4vd79pg",
    //         templateData,
    //         "user_dAFgACT8LA6YeTSYII3dT"
    //       )
    //       .then(
    //         (result: any) => {
    //         //   console.log("Sent Survey Email", result.text);
    //           userService.setSurveySent(meetingCode);
    //         },
    //         (error: any) => {
    //           console.log("Error, Survey Email Not Sent", error.text);
    //         }
    //       );
    //   }
    // }, [userService]);

    return (
        <AuthCtx.Provider value={{login: visitorLogin, getUser, authenticated, logout, ssoLogin}}>
            {children}
        </AuthCtx.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthCtx) as AuthContext
}

export default AuthProvider
import {makeStyles, Theme, withStyles} from "@material-ui/core";
import {sidebarStyles} from "Commons";

export const meetingStyleObject = (theme: Theme) => ({
    right: 96,
    background: theme.backgroundColors.grey,
    padding: theme.spacing(4, 1),
    overflow: "hidden",
    "&::-webkit-scrollbar": {
        display: "none",
    },
    zIndex : 1,
    [theme.breakpoints.down('md')]: {
        right: 69,
    },
})

export const withSideBarStyles = withStyles(theme => ({
    paper: meetingStyleObject(theme)
}))

export const withDynamicSideBarStyles = (stylesObj : (theme : Theme) =>  Record<any, any>) => makeStyles(theme => ({
    paper: stylesObj(theme)
}))

export const useStyles = makeStyles(theme => ({
    ...sidebarStyles(theme),
    root: {
        paddingTop: theme.spacing(2),
        height: "100vh",
        background: theme.backgroundColors.light,
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1,
        marginBottom: 0,
        overflowY: "auto",
        flexWrap: "nowrap",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    sidebarItem: {
        marginBottom: theme.spacing(2)
    },
    iconContainer: {
        background: "#A3A5AB",
        borderRadius: "50%",
        width: '46px',
        height: '46px',
        marginBottom: theme.spacing(2)
    },
    drawerHeader: {
        minWidth: 240,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    closeIcon: {
        padding: 0,
        color: "black"
    },
    drawerInnerContainer: {
        background: theme.backgroundColors.grey,
        padding: theme.spacing(2, 1),
        borderRadius: theme.spacing(1),
        color: "white"
    },
    activeIconText: {
        color: "black",
        fontSize: 12
    },
    passiveIconText: {
        fontSize: 12,
        color: '#8B8B97'
    },
    passiveIcon: {
        width: 32,
        height: 32,
        color: "#1B1B1B",
    },
    activeIcon: {
        width: 32,
        height: 32,
        color: "white"
    },
    activeIconContainer: {
        backgroundColor: "black!important",
        color: "black"
    },
    passiveIconContainer: {
        backgroundColor: `${theme.backgroundColors.grey} !important`,
        color: "black"
    },
    host : {
        flexGrow : 1
    },
    leave :{
        marginBottom : theme.spacing(5)
    },
    moreVert: {
        background: theme.backgroundColors.grey,
        color: 'black',
        width: '50px',
        height: '50px',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    close: {
        marginTop: 1,
        color: 'white',
    },
    closeWhite: {
        marginTop: 1,
        color: 'white',
    },
    videoCam: {
        background: theme.backgroundColors.grey,
        color: 'black',
        width: '50px',
        height: '50px',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    iconButton: {
        padding: 0,
        display: 'flex',
        alignItems: 'center'
    }
}))

export default useStyles
import {ConfigureHttpClientOptions, HttpClient} from "./types";
import {useLayoutEffect} from "react";
import {useHistory} from "react-router-dom"
import {notAuthorized} from "./utils";

const useConfigurationHttpClient = (httpClient: HttpClient, options: ConfigureHttpClientOptions) => {

    const {loginUrl, token} = options
    const history = useHistory()

    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    useLayoutEffect(() => {
        httpClient.interceptors.request.use(async config => {
            // Do something before request is sent
            return config;
        }, error => {
            // Do something with request error
            return Promise.reject(error);
        });

        httpClient.interceptors.response.use(response => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response.data;
        }, error => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (notAuthorized(error) && loginUrl) {
                history.push(loginUrl)
            }
            return Promise.reject(error.response);
        });
    }, [httpClient, history, loginUrl])

}

export default useConfigurationHttpClient
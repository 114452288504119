import React from "react";
import Box from "Commons/Box";
import Centered from "Commons/Centered";
import Title from "Commons/Title";
import Text from "Commons/Text";
import {Grid, useMediaQuery, useTheme} from "@material-ui/core";
import Conditional from "Commons/Conditional";
import useStyles from "./styles";
import { Button } from "@material-ui/core";

interface LeaveQuizModalProps {
    show: boolean
    onGoBack: () => void
    onLeave: () => void
}

const LeaveQuizModal: React.FC<LeaveQuizModalProps> = ({show, onGoBack, onLeave}) => {

    const classes = useStyles()
    const theme = useTheme()
    const isSmallWin: boolean = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Conditional cond={show}>
            <Centered classNames={{root: classes.centered, centered: classes.centered}}>
                <Box className={classes.root}>
                    <Title className={classes.title}>
                        This action will end the game for everyone.
                    </Title>
                    <Text type={isSmallWin ? "normal" : "info"} color="gray">
                        Are you sure you want to exit?
                    </Text>
                    <Grid container justify={"center"} spacing={isSmallWin ? 0 : 2} style={{marginTop: 36}}>
                        <Grid item>
                            <Button onClick={onGoBack} className={classes.dontLeaveButton}>
                                No, go back
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={onLeave} className={classes.leaveButton}>
                                Yes, exit game
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Centered>
        </Conditional>
    )
}

export default LeaveQuizModal
import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: 'white',
        color: 'black',
        padding : theme.spacing(7, 7),
        maxWidth: theme.spacing(75),
        textAlign : "center",
        zIndex : 99,
        overflow : "hidden",
        boxShadow: '0 2px 20px 0 rgba(0,0,0,0.42)',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(12, 5),
        },
    },
    dontLeaveButton : {
        background : theme.backgroundColors.light,
        padding: '20px 32px',
        border: '3px solid #dbdbdb',
        '&:hover': {
            background: '#F2F4F5'
        },
        [theme.breakpoints.down('xs')]: {
            margin: 0
        }
    },
    leaveButton : {
        background : "#232328",
        border: '2px solid #232328',
        padding: '20px 32px',
        color: 'white',
        '&:hover': {
            background: 'black',
            border: '2px solid #black',
        },
        [theme.breakpoints.down('xs')]: {
            margin: 0
        }
    },
    centered : {
        position : "fixed",
        zIndex : 99
    },
    icon : {
        position: "absolute",
        bottom: theme.spacing(8),
        color : "black"
    },
    title: {
        fontSize: 26,
        color: 'black!important',
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            fontWeight: 500
        },
    },
    subInfo: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 10,
        },
    },
}))

export default useStyles

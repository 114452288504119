import React from "react";
import {Grid, GridProps} from "@material-ui/core";
import useStyles from "./styles";
import cl from "classnames"

type ContainerProps = {} & GridProps

const Box: React.FC<ContainerProps> = ({children, ...rest}) => {
    const {className, ...props} = rest
    const classes = useStyles()
    return (
        <Grid className={cl(classes.root, className)} {...props}>
            {children}
        </Grid>
    )
}

export default Box
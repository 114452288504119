import React from "react";

type ConditionalProps = {
    cond : any
    onFalse ?: JSX.Element
    children : any
}

const Conditional : React.FC<ConditionalProps> = (props) => {

    const {children, cond, onFalse} = props

    if(cond){
        return children
    }

    if(onFalse){
        return onFalse
    }

    return null
}

export default Conditional
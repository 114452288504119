import {device} from "Utils";

class MeetingPolicies {

    constructor() {
        this.shouldHideVideo = this.shouldHideVideo.bind(this)
    }

    shouldHideVideo(host : boolean) {
        return !host && device.isMobile()
    }
}

export default MeetingPolicies
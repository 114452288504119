import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    videoElement : {
        marginBottom: theme.spacing(2),
        height: "calc(100vh - 80px)", 
        overflow: "hidden",
    },
    screenShareVideoElement : {
        marginBottom: theme.spacing(2),
        width: "94vw",
        height: "calc(100vh - 80px)", 
        overflow: "hidden",
        [theme.breakpoints.down('lg')]: {
            width: "92vw",
        },
        [theme.breakpoints.down('sm')]: {
            width: "80vw",
        },
    },
    videoIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        borderRadius: "50%",
        width: '46px',
        height: '46px',
    },
    granted : {
        position : "absolute",
        width : 11,
        height : 11,
        right : -10,
        top : -10,
        borderRadius : "50%",
        background : theme.colors.success
    },
    shareFullModal: {
        boxShadow: "0 2px 20px 0 rgba(0,0,0,0.42)",
        position: "relative",
        padding: theme.spacing(4),
        width: 'auto',
        textAlign: "center",
    },
    shareFullModalImg: {
        margin: "auto",
        transform: "rotate(90deg)"
    },
    shareFullModalTitle : {
        marginTop: theme.spacing(4),
        fontSize: 20,
    },
    passiveIcon: {
        width: 32,
        height: 32,
        color: "#1B1B1B",
    },
    activeIcon: {
        width: 32,
        height: 32,
        color: "white"
    },
}))

export default useStyles
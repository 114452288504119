import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(-10),
        left : theme.spacing(1),
        transition: "all 1s"
    },
    showRoot : {
        bottom: theme.spacing(4),
    },
    button: {
        background : theme.backgroundColors.dark,
        color : "white"
    }
}))

export default useStyles

import * as types from "./types"
import {Action} from "./actions";
import {PlayerBalance} from "Core/PlayerBalance/types";

const initialState: PlayerBalance = {
   userId : "",
    points : 0
};

const leaderboardReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case types.SET_PLAYER_BALANCE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};

export default leaderboardReducer;

import {makeStyles} from "@material-ui/core";

const useStyles = (size ?: number) => makeStyles(({
    imageContainer: {
        width : size,
        height : size,
        "& img": {
            width: "100%",
            verticalAlign : "middle"
        }
    },
}))

export default useStyles
import * as types from "./types"
import {Action} from "./actions";
import {QuizInstance} from "../core/types";
import QuizPolicies from "../core/QuizPolicies";

const policies = new QuizPolicies()
export const initialState: QuizInstance = {
    id: "",
    hostUserId : "",
    answeredQuestions: {"": []},
    gameState: "",
    players: {
        "": ""
    },
    gameDefinition: {
        id: "",
        name: "",
        questions: [],
        rewardsConfiguration : {
            hostReward : {
                amount : 0,
                prizeType : "",
                coin : ""
            },
            guestRewards : []
        }
    },
    leaderboard : {
        id: "",
        name: "",
        gameInstanceId: "",
        gameInstanceName: "",
        players : []
    }
};

const gameInstanceReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case types.SET_INSTANCE:
            return {
                ...state,
                ...action.payload,
            }
        case types.ADD_JOINED_PLAYER:
            return {
                ...state,
                players: {
                    ...state.players,
                    ...policies.joinPlayer(action.payload, state.gameState)
                }
            }
        case types.REMOVE_JOINED_PLAYER:

            delete state.players[action.payload.userId]

            return {
                ...state,
                players: {
                    ...state.players,
                }
            }
        case types.SET_ANSWER_COUNT:
            const userId = action.payload.userId
            return {
                ...state,
                answeredQuestions: {
                    ...state.answeredQuestions,
                    [userId]: policies.setAnswers(action.payload, state.answeredQuestions[userId])
                }
            }
        case types.SET_GAME_INSTANCE_LEADERBOARD:
            return {
                ...state,
                leaderboard : {
                    ...state.leaderboard,
                    ...action.payload
                }
            }
        default:
            return state;
    }
};

export default gameInstanceReducer;

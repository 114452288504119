import {OnPlayerPointsCallback, OnPlayerPointsData} from "./types";
import PlayerBalanceRepository from "./PlayerBalanceRepository";

class PlayerBalance {

    constructor(private playerBalanceRepo : PlayerBalanceRepository) {
        this.onPlayerPoints = this.onPlayerPoints.bind(this)
    }

    onPlayerPoints(cb : OnPlayerPointsCallback){
        window.addEventListener("message", (event: {data : OnPlayerPointsData}) => {
            if(event.data.action === "earned"){
                cb(event.data.data)
            }
        })
    }
}

export default PlayerBalance
import {HttpClient, Token} from "./types";
import jwtDecode from "jwt-decode";
import moment from "moment";

const baseRoute = "/api/user"

class TokenService {

    constructor(private httpClient: HttpClient) {
        this.refresh = this.refresh.bind(this)
        this.store = this.store.bind(this)
        this.isExpired = this.isExpired.bind(this)
        this.beforeExpirationMinutes = this.beforeExpirationMinutes.bind(this)
    }

    async refresh(): Promise<string> {
        const res = await this.httpClient.post<any, any>(`${baseRoute}/refresh-token`)
        return res.accessToken
    }

    store(token: string) {
        localStorage.setItem("accessToken", token)
        this.httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    isExpired() {
        const accessToken = this.get()
        if(!accessToken){
            return true
        }
        const decoded = jwtDecode<Token>(accessToken);
        const expirationDelta = moment.unix(decoded.exp).diff(moment(), 'minutes');
        const isExpired = expirationDelta <= 1;
        return isExpired;
    }

    beforeExpirationMinutes(){
        const accessToken = this.get()
        if(!accessToken){
            return 0
        }
        const decoded = jwtDecode<Token>(accessToken);
        const expirationDelta = moment.unix(decoded.exp).diff(moment(), 'minutes');
        if(expirationDelta <= 1){
            return 0
        }
        return expirationDelta
    }

    remove(){
        localStorage.removeItem("accessToken")
    }

    get(): string {
        return localStorage.getItem("accessToken") || ""
    }
}

export default TokenService
import * as types from "./types";
import {QuizInstance, OnAnswerData, Leaderboard} from "../core/types";

export const setInstance = (instance: QuizInstance) => ({
    type: types.SET_INSTANCE,
    payload: instance
} as const)

export const fetchInstance = (definitionId: string, userId : string) => ({
    type: types.FETCH_INSTANCE,
    payload: {definitionId, userId}
} as const)

export const setAnswerCount = (answerData: OnAnswerData) => ({
    type: types.SET_ANSWER_COUNT,
    payload: answerData
} as const)

export const addJoinedPlayer = (player: { userId : string }) => ({
    type: types.ADD_JOINED_PLAYER,
    payload: player
} as const)

export const removeJoinedPlayer = (player: { userId : string }) => ({
    type: types.REMOVE_JOINED_PLAYER,
    payload: player
} as const)

export const setGameInstanceLeaderboard = (leaderboard: Leaderboard) => ({
    type: types.SET_GAME_INSTANCE_LEADERBOARD,
    payload: leaderboard
} as const)

export type Action = ReturnType<typeof setInstance>
    | ReturnType<typeof fetchInstance>
    | ReturnType<typeof setAnswerCount>
    | ReturnType<typeof addJoinedPlayer>
    | ReturnType<typeof removeJoinedPlayer>
    | ReturnType<typeof setGameInstanceLeaderboard>
import React, {createContext, useContext} from "react";

type VideoData = {
    open: boolean
    openVideo: () => void
    closeVideo: () => void
}

export const VideoCtx = createContext({})

export const VideoProvider: React.FC<VideoData> = ({
                                                       children,
                                                       ...props
                                                   }) => (
    <VideoCtx.Provider value={{...props}}>
        {children}
    </VideoCtx.Provider>
)

export const useVideo = () => {
    return useContext(VideoCtx) as VideoData
}